import { ThemeProps as Prop, ColorProfiles } from '@powdr/constants';

import { componentThemesDefault } from './shared-theme-data';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#3F3F3F',
    [Prop.CONTENT]: '#3F3F3F',
    [Prop.LINK_TXT]: '#009bd1',
    [Prop.LINK_TXT_HOVER]: '#009bd1',
    [Prop.BTN_BG]: '#009bd1',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#3F3F3F',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#009bd1',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#b3b3b3',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#3F3F3F',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#d9d9d9',
    [Prop.ICON]: '#009bd1',
    [Prop.ICON_HOVER]: '#3f3f3f',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#009bd1',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#3f3f3f',
    [Prop.LINK_TXT_HOVER]: '#3f3f3f',
    [Prop.BTN_BG]: '#FFFFFF',
    [Prop.BTN_TXT]: '#009bd1',
    [Prop.BTN_BG_HOVER]: '#3F3F3F',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#FFFFFF',
    [Prop.BTN_TXT_ACTIVE]: '#009bd1',
    [Prop.BTN_BG_INACTIVE]: '#3F3F3F',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#FFFFFF',
    [Prop.ELEMENT_TXT]: '#3F3F3F',
    [Prop.BORDER]: '#3F3F3F',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#3F3F3F',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#F2F2F2',
    [Prop.HEADER]: '#3F3F3F',
    [Prop.CONTENT]: '#3F3F3F',
    [Prop.LINK_TXT]: '#009bd1',
    [Prop.LINK_TXT_HOVER]: '#009bd1',
    [Prop.BTN_BG]: '#009bd1',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#3F3F3F',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#009bd1',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#3F3F3F',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#009bd1',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#3F3F3F',
    [Prop.ICON]: '#009bd1',
    [Prop.ICON_HOVER]: '#3f3f3f',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#B3B3B3',
    [Prop.HEADER]: '#3F3F3F',
    [Prop.CONTENT]: '#3F3F3F',
    [Prop.LINK_TXT]: '#009bd1',
    [Prop.LINK_TXT_HOVER]: '#009bd1',
    [Prop.BTN_BG]: '#FFFFFF',
    [Prop.BTN_TXT]: '#009bd1',
    [Prop.BTN_BG_HOVER]: '#3F3F3F',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#009bd1',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#3f3f3f',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#009bd1',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#3f3f3f',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#e8f5fa',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#3F3F3F',
    [Prop.CONTENT]: '#3F3F3F',
    [Prop.LINK_TXT]: '#009bd1',
    [Prop.LINK_TXT_HOVER]: '#009bd1',
    [Prop.BTN_BG]: '#009bd1',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#3F3F3F',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#009bd1',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#3F3F3F',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#009bd1',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#3F3F3F',
    [Prop.ICON]: '#009bd1',
    [Prop.ICON_HOVER]: '#3f3f3f',
  },
  ...componentThemesDefault,
};
