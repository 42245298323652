import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const woodwardTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#666666',
    [Prop.LINK_TXT_HOVER]: '#ffd200',
    [Prop.BTN_BG]: '#ffd200',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#000000',
    [Prop.BTN_TXT_ACTIVE]: '#ffd200',
    [Prop.BTN_BG_INACTIVE]: '#b4b4b4',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#e0e0e0',
    [Prop.ICON]: '#ffd200',
    [Prop.ICON_HOVER]: '#000000',
    [Prop.ICON_2]: '#ffd200',
    [Prop.ICON_HOVER_2]: '#000000',
    [Prop.HEADER_BG]: '#ffd200',
    [Prop.HEADER_TXT]: '#000000',
    [Prop.HEADER_ICON]: '#FFFFFF',
    [Prop.HEADER_BG_2]: '#000000',
    [Prop.HEADER_TXT_2]: '#ffd200',
    [Prop.HEADER_ICON_2]: '#FFFFFF',
    [Prop.ODD_EVEN_OFFSET]: '#e0e0e0',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#ffd200',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#000000',
    [Prop.LINK_TXT_HOVER]: '#666666',
    [Prop.BTN_BG]: '#000000',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#FFD200',
    [Prop.BTN_BG_ACTIVE]: '#ffffff',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#A29B6B',
    [Prop.BTN_TXT_INACTIVE]: '#000000',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#000000',
    [Prop.ICON]: '#000000',
    [Prop.ICON_HOVER]: '#FFFFFF',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#000000',
    [Prop.HEADER]: '#ffd200',
    [Prop.CONTENT]: '#ffffff',
    [Prop.LINK_TXT]: '#ffffff',
    [Prop.LINK_TXT_HOVER]: '#ffd200',
    [Prop.BTN_BG]: '#ffd200',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#ffd200',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#ffffff',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#4d4d4d',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#ffd200',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#ffd200',
    [Prop.ICON]: '#ffffff',
    [Prop.ICON_HOVER]: '#ffd200',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#3a3a3a',
    [Prop.HEADER]: '#ffd200',
    [Prop.CONTENT]: '#ffffff',
    [Prop.LINK_TXT]: '#ffd200',
    [Prop.LINK_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG]: '#ffd200',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#000000',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#757575',
    [Prop.BTN_TXT_INACTIVE]: '#ffd200',
    [Prop.ELEMENT]: '#ffffff',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#696969',
    [Prop.ICON]: '#ffffff',
    [Prop.ICON_HOVER]: '#ffd200',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#ffd200',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#000000',
    [Prop.LINK_TXT_HOVER]: '#666666',
    [Prop.BTN_BG]: '#000000',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#FFD200',
    [Prop.BTN_BG_ACTIVE]: '#ffffff',
    [Prop.BTN_TXT_ACTIVE]: '#ffd200',
    [Prop.BTN_BG_INACTIVE]: '#A29B6B',
    [Prop.BTN_TXT_INACTIVE]: '#ffd200',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#000000',
    [Prop.ICON]: '#000000',
    [Prop.ICON_HOVER]: '#FFFFFF',
  },
};

export const componentThemesDefault = {
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.NAV_BG]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#3F3F3F',
    [Prop.NAV_ITEM_TXT_SOLID]: '#3F3F3F',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#FFFFFF',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#009bd1',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#009bd1',
    [Prop.WW_ICON_COLOR]: '#FFD200',
    [Prop.LOGO_FOCUS_COLOR]: '#009bd1',
  },

  [Components.MOBILE_NAV]: {
    [Prop.MENU_BTN_BG]: '#FFFFFF',
    [Prop.MENU_BTN_ICON]: '#009bd1',
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.CLOSE_BTN_BG]: '#009bd1',
    [Prop.CLOSE_BTN_ICON]: '#FFFFFF',
    [Prop.MAIN_NAV_BG]: '#FFFFFF',
    [Prop.MAIN_NAV_TXT]: '#3F3F3F',
    [Prop.MAIN_NAV_BORDER]: '#3F3F3F',
    [Prop.WW_ICON_COLOR]: '#FFD200',
    [Prop.DRAWER_BTN_BG]: '#FFFFFF',
    [Prop.DRAWER_BTN_TXT]: '#3F3F3F',
    [Prop.PROMO_BTN_BG]: '#FFFFFF',
    [Prop.PROMO_BTN_TXT]: '#3F3F3F',
    [Prop.SUB_NAV_BG]: '#009bd1',
    [Prop.SUB_NAV_TXT]: '#FFFFFF',
    [Prop.SUB_NAV_BORDER]: '#3F3F3F',
    [Prop.NAV_PROMO_ITEM_TEXT]: '#FFFFFF',
  },

  [Components.STICKY_NAV]: {
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_TXT_HOVER]: '#3F3F3F',
    [Prop.BTN_BG_ACTIVE]: '#FFFFFF',
    [Prop.BTN_TXT_ACTIVE]: '#3F3F3F',
  },

  [Components.WEATHER_FEED]: {
    [Prop.BACKGROUND]: '#009bd1',
    [Prop.ICON]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
  },

  [Components.PROMOTIONS_FEED]: {
    [Prop.BACKGROUND]: '#009bd1',
    [Prop.LINK_TXT]: '#FFFFFF',
  },

  [Components.ALERT_BAR]: {
    [Prop.BACKGROUND]: '#009bd1',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#3f3f3f',
    [Prop.LINK_TXT_HOVER]: '#3f3f3f',
    [Prop.BTN_BG]: '#FFFFFF',
    [Prop.BTN_TXT]: '#009bd1',
    [Prop.BTN_BG_HOVER]: '#3F3F3F',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#3F3F3F',
  },

  [Components.ECOMMERCE_BANNER]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.LINK_TXT]: '#009bd1',
    [Prop.LINK_TXT_HOVER]: '#3f3f3f',
    [Prop.TOGGLE_ACTIVE]: '#009bd1',
    [Prop.TOGGLE_INACTIVE]: '#3f3f3f',
    [Prop.ELEMENT]: '#009bd1',
    [Prop.LINK_SEPARATOR]: '#3f3f3f',
  },

  [Components.MEGA_NAV]: {
    [Prop.LINK_TXT]: '#3f3f3f',
    [Prop.LINK_TXT_HOVER]: '#009bd1',
    [Prop.BORDER]: '#3f3f3f',
  },

  [Components.FOOTER]: {
    [Prop.BACKGROUND]: '#B3B3B3',
    [Prop.HEADER]: '#3F3F3F',
    [Prop.BTN_BG]: '#FFFFFF',
    [Prop.BTN_TXT]: '#009bd1',
    [Prop.BTN_BG_HOVER]: '#3F3F3F',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
  },

  [Components.FOOTER_NAV]: {
    [Prop.LINK_TXT]: '#3f3f3f',
    [Prop.LINK_TXT_HOVER]: '#009bd1',
  },

  [Components.COPYRIGHT]: {
    [Prop.BACKGROUND]: '#009bd1',
    [Prop.LINK_TXT]: '#3f3f3f',
  },

  [Components.NAVIGATION_DRAWER]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.CLOSE_BTN_BG]: '#FFFFFF',
    [Prop.CLOSE_BTN_ICON]: '#009bd1',
    [Prop.NAV_PROMO_ITEM_TEXT]: '#FFFFFF',
  },

  [Components.NAVIGATION_TOGGLE]: {
    [Prop.NAV_TOGGLE_TXT]: '#FFFFFF',
    [Prop.NAV_TOGGLE_ICON]: '#FFFFFF',
    [Prop.NAV_TOGGLE_TXT_SCROLLED]: '#009bd1',
    [Prop.NAV_TOGGLE_ICON_SCROLLED]: '#009bd1',
  },

  [Components.DOR_DRAWER]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.ICON]: '#009bd1',
    [Prop.HEADER]: '#000000',
    [Prop.LINK_TXT]: '#009bd1',
    [Prop.LINK_TXT_HOVER]: '#009bd1',
  },

  [Components.DOR_TOGGLE]: {
    [Prop.BACKGROUND]: '#009bd1',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.ICON]: '#FFFFFF',
  },

  [Components.SIDEBAR]: {
    [Prop.BACKGROUND]: '#009bd1',
    [Prop.CONTENT]: '#FFFFFF',
  },

  [Components.SIDEBAR_TRAY]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.CONTENT]: '#3f3f3f',
  },

  [Components.MAIN_NAV]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.BORDER]: '#000000',
    [Prop.CONTENT]: '#000000',
  },

  [Components.GRID]: {
    [Prop.PAGINATION]: '#3f3f3f',
    [Prop.PAGINATION_ACTIVE]: '#3f3f3f',
    [Prop.PAGINATION_HOVER]: '#3f3f3f',
  },

  [Components.UI_TABLE]: {
    [Prop.HEADER_BG]: '#b3b3b3',
  },
};
